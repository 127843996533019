import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class App extends Vue {
    private xrSupported = false;

    public async mounted(): Promise<void> {
        // Check if WebXR is Supported
        this.xrSupported = navigator.xr !== undefined;

        if (this.xrSupported && navigator.xr) {
            // Check for immersive mode for Chrome on Desktop
            this.xrSupported = await navigator.xr.isSessionSupported('immersive-ar');
        }

        this.$store.commit('setXRSupported', this.xrSupported);

        // Use German only for Jumbo Xmas Tree Demo
        this.$i18n.locale = 'de';

        if (this.xrSupported) {
            this.retrieveModels();
        }
    }

    private async retrieveModels(): Promise<void> {
        if (this.$store.getters.getXRSupported == true) {
            try {
                this.$store.dispatch('retrieveModels');
            } catch (err: any) {
                console.log(err);
            }
        }
    }
}
