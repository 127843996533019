const state: Global = {
    xrSupported: false,
    xrActive: false,
    uiActive: false,
    showShoppingCart: false,
    showFinishedDemoAlert: false,
    showLeaveWebXrAlert: false,
    showHoverHint: false,
    showTapToPlaceHint: false,
    tutorialActive: true,
};

const getters = {
    getXRSupported(state: Global): boolean {
        return state.xrSupported;
    },

    getXRActive(state: Global): boolean {
        return state.xrActive;
    },

    getUIActive(state: Global): boolean {
        return state.uiActive;
    },

    getShowShoppingCart(state: Global): boolean {
        return state.showShoppingCart;
    },

    getShowFinishedDemo(state: Global): boolean {
        return state.showFinishedDemoAlert;
    },

    getShowLeaveWebXrAlert(state: Global): boolean {
        return state.showLeaveWebXrAlert;
    },

    getShowHoverHint(state: Global): boolean {
        return state.showHoverHint;
    },

    getShowTapToPlaceHint(state: Global): boolean {
        return state.showTapToPlaceHint;
    },

    getTutorialActive(state: Global): boolean {
        return state.tutorialActive;
    },

    getAreAnyAlertsActive(state: Global): boolean {
        if (state.showFinishedDemoAlert || state.showLeaveWebXrAlert) {
            return true;
        } else {
            return false;
        }
    },
};

const mutations = {
    setXRSupported(state: Global, xrSupported: boolean): void {
        state.xrSupported = xrSupported;
    },

    setXRActive(state: Global, xrActive: boolean): void {
        state.xrActive = xrActive;
    },

    setUIActive(state: Global, uiActive: boolean): void {
        state.uiActive = uiActive;
    },

    setShowShoppingCart(state: Global, showShoppingCart: boolean): void {
        state.showShoppingCart = showShoppingCart;
    },

    setShowFinishedDemoAlert(state: Global, userFinishedDemo: boolean): void {
        state.showFinishedDemoAlert = userFinishedDemo;
    },

    setShowLeaveWebXrAlert(state: Global, showLeaveWebXrAlert: boolean): void {
        state.showLeaveWebXrAlert = showLeaveWebXrAlert;
    },

    setShowHoverHint(state: Global, showHoverHint: boolean): void {
        state.showHoverHint = showHoverHint;
    },

    setShowTapToPlaceHint(state: Global, showTapToPlaceHint: boolean): void {
        state.showTapToPlaceHint = showTapToPlaceHint;
    },

    setTutorialActive(state: Global, tutorialActive: boolean): void {
        state.tutorialActive = tutorialActive;
    },
};

const actions = {
    showTapToPlaceHintDelayed(context: any): void {
        if (context.getters.getTutorialActive) {
            setTimeout(() => {
                if (context.getters.getPlacedModels.length == 0) {
                    context.commit('setShowTapToPlaceHint', true);
                }
            }, 2000);
            context.commit('setTutorialActive', false);
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};

interface Global {
    xrSupported: boolean;
    xrActive: boolean;
    uiActive: boolean;
    showShoppingCart: boolean;
    showFinishedDemoAlert: boolean;
    showLeaveWebXrAlert: boolean;
    showHoverHint: boolean;
    showTapToPlaceHint: boolean;
    tutorialActive: boolean;
}
