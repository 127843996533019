import Vue from 'vue';
import Vuex from 'vuex';
import globalstate from './modules/globalstate'
import webxrstate from './modules/webxrstate';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        globalstate,
        webxrstate,
    },
});
