import Vue from 'vue';
import router from './router';
import VueMatomo from 'vue-matomo';

/**
 * Initialize Matomo Analytics
 * https://github.com/AmazingDreams/vue-matomo
 */
function initMatomo(): void {
    if (!process.env.VUE_APP_MATOMO_TRACKER_URL || !process.env.VUE_APP_MATOMO_SITE_ID) {
        console.warn('Matomo not configured -> disabled');
        return;
    }

    Vue.use(VueMatomo, {
        // Configure your matomo server and site by providing
        host: process.env.VUE_APP_MATOMO_TRACKER_URL,
        siteId: process.env.VUE_APP_MATOMO_SITE_ID,

        // Enables automatically registering pageviews on the router
        router: router,

        // Enables link tracking on regular links. Note that this won't
        // work for routing links (ie. internal Vue router links)
        // Default: true
        enableLinkTracking: true,

        // Require consent before sending tracking information to matomo
        // Default: false
        requireConsent: false,

        // Whether to track the initial page view
        // Default: true
        trackInitialView: true,

        // Whether or not to log debug information
        // Default: false
        debug: false,
    });
}

export { initMatomo };
